// 共有ディレクトリのツリー構造の取得

import api from './api'

const path = '/shared-directory-tree'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
