// ディレクトリ構成の更新

import api from '../api'

const path = '/admin/directory-trees/{id}'

export default (directoryTreeId, tree) => {
  const url = path.replace('{id}', directoryTreeId)
  return api({
    method: 'put',
    url,
    params: {
      tree,
    },
    auth: true,
  })
}
