// タブやフォルダやファイルの並べ替え

<template lang="pug">
el-dialog.node-sort-dialog(
  title='並べ替え',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article
    ul
      li(v-for='item, i in nodes')
        span {{ item.name }}
        el-button-group.control
          el-button(
            icon='el-icon-arrow-up',
            size='small',
            @click='moveForward(i)'
            :disabled='i === 0'
          ) 前
          el-button(
            size='small',
            @click='moveBackward(i)'
            :disabled='i === nodes.length - 1'
          )
            | 後&nbsp;
            i.el-icon-arrow-down.el-icon-right
    footer
      el-button(type='primary', @click='close(true)') 決定
      el-button(@click='close(false)') キャンセル
</template>

<script>
export default {
  name: 'NodeSortDialog',

  data() {
    return {
      nodes: [],
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 対象のノード群
    value: {
      type: Array,
    },
  },

  methods: {
    async open() {
      // シャローコピーを作成
      this.nodes = [...this.value]
    },
    moveForward(index) {
      const item = this.nodes[index]
      this.nodes.splice(index, 1)
      this.nodes.splice(index - 1, 0, item)
    },
    moveBackward(index) {
      this.moveForward(index + 1)
    },
    close(success) {
      if (success) {
        this.$emit('input', this.nodes)
        this.$emit('ok')
      }
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.node-sort-dialog
  article
    ul
      list-style-type: none
      li
        border: 1px solid $grey-light
        padding: 0.5rem 1rem
        border-radius: 3px
        overflow: hidden
        line-height: 2.2
        &:nth-child(n+2)
          margin-top: 0.5rem
        .control
          float: right
    footer
      margin-top: 1rem
</style>
