// 管理者向けの書類や様式の管理ページ

<template lang="pug">
el-card.admin-documents

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  .overlay(v-show='$refs.upload && $refs.upload.dropActive')
    span ドロップでファイルを追加

  header
    .group-1
      el-button(
        icon='el-icon-circle-plus',
        @click='addTab'
      ) タブを追加
      el-button(
        icon='el-icon-circle-plus',
        @click='addFolder'
        :disabled='!currentTab'
      ) 現在のタブにフォルダを追加
    .group-2
      el-button(
        type='text',
        @click='sortTab',
        :disabled='!tree || !tree.children || tree.children.length < 2'
      ) タブの並べ替え
      el-button(
        type='text',
        @click='sortFolder',
        :disabled='!currentTab || !currentTab.children || currentTab.children.length < 2'
      ) フォルダの並べ替え
      el-button(
        type='text',
        @click='sortFile',
        :disabled='!currentFolder || !currentFolder.children || currentFolder.children.length < 2'
      ) ファイルの並べ替え
      el-button(
        type='text',
        @click='removeCurrentTab',
        :disabled='!currentTab'
      ) 現在のタブを削除
      el-button(
        type='text',
        @click='removeCurrentFolder',
        :disabled='!currentFolder'
      ) 現在のフォルダを削除

    p.howto
      i.el-icon-info
      | &nbsp;ファイルをアップロードするには、アップロード対象のフォルダを開いた状態で、ファイルをドロップしてください。

  article(v-if='tree')
    el-tabs(type='card', v-model='activeTab')
      el-tab-pane(
        v-for='item in tabs',
        :key='item.id',
        :label='item.name'
      )
        el-collapse(v-model='activeFolder', accordion)
          el-collapse-item(
            v-for='folder in item.children',
            :key='folder.id',
            :name='folder.id'
          )
            template(slot='title')
              .folder {{ folder.name }}
            p.empty(v-if='!folder.children || folder.children.length === 0')
              | ファイルがありません
            ul.files(v-else)
              li(v-for='file in folder.children')
                a.filename(:href='urlMap[file.id]', target='_blank', rel='noopener')
                  i.el-icon-document
                  | {{ file.name }}
                a.delete-file(@click='removeFile(file.id)')
                  i.el-icon-error
                span {{ file.id | timestampToJpDate }}

  file-upload.uploader(
    ref='upload',
    :custom-action='uploadAsync',
    :drop='!!currentFolder',
    v-model='files',
    @input-file='inputFile'
  )

  node-sort-dialog(
    :visible.sync='dialogVisible',
    v-model='sortTargetNodes',
    @ok='sortOk'
  )
</template>

<script>
import get from 'lodash.get'
import FileUpload from 'vue-upload-component'

import getSharedDirectoryTree from '@/api/get-shared-directory-tree'
import getFileUploadUrl from '@/api/admin/get-file-upload-url'
import uploadFile from '@/api/admin/upload-file'
import updateDirectoryTree from '@/api/admin/update-directory-tree'

import NodeSortDialog from '@/dialogs/node-sort-dialog'

export default {
  name: 'AdminDocuments',

  components: {
    FileUpload,
    NodeSortDialog,
  },

  data() {
    return {
      tree: null,
      urlMap: {},
      activeTab: null,
      activeFolder: null,
      files: [],
      dialogVisible: false,
      sortTargetNodes: [],
      sortType: null,
    }
  },

  computed: {
    tabs() {
      return get(this.tree, 'children', [])
    },
    currentTab() {
      if (!this.activeTab) return null
      else {
        const i = parseInt(this.activeTab, 10)
        return this.tabs[i]
      }
    },
    currentFolder() {
      if (!this.currentTab) return null
      else if (!this.activeFolder) return null
      for (const item of this.currentTab.children) {
        if (item.id === this.activeFolder) return item
      }
      return null
    },
  },

  async created() {
    const response = await getSharedDirectoryTree()
    if (!response.ok) {
      return
    }
    this.tree = response.payload.tree
    this.urlMap = Object.assign({}, this.urlMap, response.payload.urlMap)
  },

  methods: {
    // タブの追加 = 第一階層のフォルダの作成
    async addTab() {
      try {
        const { value } = await this.$prompt('タブの名前を入力してください。', 'タブの作成', {
          confirmButtonText: '作成',
          cancelButtonText: 'キャンセル',
          inputPattern: /.{1,}/,
          inputErrorMessage: '名前を入力してください。',
        })
        await this._addNode(this.tree, value)
      } catch (e) {
        // 何もしない
      }
    },
    async addFolder() {
      try {
        const { value } = await this.$prompt(
          'フォルダの名前を入力してください。',
          'フォルダの作成',
          {
            confirmButtonText: '作成',
            cancelButtonText: 'キャンセル',
            inputPattern: /.{1,}/,
            inputErrorMessage: '名前を入力してください。',
          }
        )
        await this._addNode(this.currentTab, value)
      } catch (e) {
        // 何もしない
      }
    },
    // ツリー構造のノード追加
    _addNode(parent, name, id = null) {
      if (!parent.children) parent.children = []
      parent.children.push({
        id: id || +new Date(),
        name,
        children: null,
      })
      // 内容を保存
      return this.saveTree()
    },
    async uploadAsync(file) {
      const response = await getFileUploadUrl('share', file.name)
      if (!response.ok) return
      const { getUrl, putUrl, id } = response.payload
      const response2 = await uploadFile(putUrl, file.file)
      if (!response2.ok) return
      await this._addNode(this.currentFolder, file.name, id)
      this.urlMap = Object.assign({}, this.urlMap, { [id]: getUrl })
    },
    inputFile() {
      if (this.$refs.upload) {
        if (!this.$refs.upload.active) this.$refs.upload.active = true
      }
    },

    async removeCurrentTab() {
      await this._remove(this.tree, this.currentTab.id)
    },
    async removeCurrentFolder() {
      await this._remove(this.currentTab, this.activeFolder)
    },
    async removeFile(id) {
      await this._remove(this.currentFolder, id)
    },
    async _remove(parentNode, targetId) {
      try {
        await this.$confirm('本当に削除してもよろしいですか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
        parentNode.children = parentNode.children.filter((item) => item.id !== targetId)
        await this.saveTree()
      } catch (e) {
        // 何もしない
      }
    },

    sortTab() {
      this.sortType = 'tab'
      this.sortTargetNodes = this.tree.children
      this.dialogVisible = true
    },
    sortFolder() {
      this.sortType = 'folder'
      this.sortTargetNodes = this.currentTab.children
      this.dialogVisible = true
    },
    sortFile() {
      this.sortType = 'file'
      this.sortTargetNodes = this.currentFolder.children
      this.dialogVisible = true
    },
    sortOk() {
      if (this.sortType === 'tab') this.tree.children = this.sortTargetNodes
      else if (this.sortType === 'folder') this.currentTab.children = this.sortTargetNodes
      else if (this.sortType === 'file') {
        this.currentFolder.children = this.sortTargetNodes
      } else return
      // フォルダを未選択に
      this.activeFolder = null
      // 内容の保存
      return this.saveTree()
    },
    // ディレクトリ構造を保存
    saveTree() {
      const directoryName = 'share'
      return updateDirectoryTree(directoryName, this.tree)
    },
  },

  watch: {
    activeTab() {
      this.activeFolder = null
    },
  },
}
</script>

<style lang="sass">
.admin-documents
  .el-collapse-item__content
    padding: 0 0.5rem 1rem
  .el-tabs__item
    font-size: 1rem
    &.is-active
      font-weight: bold
      text-decoration: underline
  .el-collapse-item__header
    font-size: 1rem
    &.is-active
      font-weight: bold
</style>

<style lang="sass" scoped>
.admin-documents

  .howto
    color: #24529c
    font-size: 0.95rem

  .overlay
    position: fixed
    background: rgba($white, 0.6)
    left: 0
    right: 0
    top: 0
    bottom: 0
    display: flex
    border: 10px solid $grey
    z-index: 9999
    align-items: center
    justify-content: center
    span
      font-size: 2rem

  article
    margin-top: 0.5rem

    p.empty
      background: $white-ter
      padding: 1rem
      text-align: center
      font-size: 1rem
      border-radius: 3px
      color: #a72b2b
    ul.files
      border-bottom: 2px solid $white-ter
      background: #fefff5
      li
        padding: 0.5rem
        font-size: 1rem
        overflow: hidden
        border-top: 2px solid $white-ter
        a.filename
          i
            margin-right: 0.5rem
        a.delete-file
          margin-left: 1rem
          color: #940b29
          cursor: pointer
        span
          float: right
          color: darken($color-1, 20%)

    .folder
      margin-left: 0.5rem
      background-image: url('../../assets/folder-icon.png')
      background-size: 1.5rem
      background-position: center left
      background-repeat: no-repeat
      padding-left: 1.8rem

  // IE11でファイル選択欄が表示されてしまうので
  .uploader
    visibility: hidden
</style>
